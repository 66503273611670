.content {
  height: 120px;
  color: #999999;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  user-select: none;
}
.adm-tabs-header {
  border-bottom: none!important;
}