.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  .inputContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 20 15;
  }
}
.adm-tabs-tab {
  font-size: 16px !important;
}